@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

p.featured {
        font-weight: 600;
        font-size: 1.3em;
        line-height: 35px;
}

#header a {
        font-size: 1.1em;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
}

#header h1 {
        color: #ffffff;
        font-size: 1.6em;
        line-height: 1.75em;
        margin: 0;
}

#header {
        background-size: cover;
}

h1 {
        line-height: 1.9em;
}

h2 {
        line-height: 1.7em;
}
